import React from "react";
import "../../styles/settings/settings.css";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";
import {
  ComboBox,
  DropDownList,
  MultiSelect
} from "@progress/kendo-react-dropdowns";
import { DatePicker, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { sampleTransactionalLog } from "./transactionalLogSample";
import { format } from "date-fns";
import { settingsFieldName } from "../../utils/settingsFieldName";
import FieldNamesDropdown from "./FieldNameDropdown";

const TransactionalLogPanel = ({
  dateValue,
  handleDateChange,
  handleUserChange,
  handleOpenUsersDropdown,
  usersData,
  data,
  page,
  pageSizeValue,
  pageChange,
  handleFieldName,
  fieldNameValue,
  fieldNameConfig
}) => {
  const { t } = useTranslation();

  const transactionalLogsCell = ({ dataItem }) => {
    return (
      <td>
        <div>
          <strong>
            {format(new Date(`${dataItem.dateChanged}Z`), "dd/MM/yyyy p")}
          </strong>
        </div>

        <span>
          <strong>"{dataItem.fieldNameFormatted}"</strong> was modified by{" "}
          <strong>"{dataItem.changedByName}"</strong> from{" "}
          {dataItem.oldValueFormatted} to {dataItem.newValueFormatted}
        </span>
      </td>
    );
  };

  const customStartDateInput = (props) => {
    return (
      <label>
        <span className="date-label">{getTranslation("Start", t)}</span>
        <DatePicker {...props} label={undefined} show={false}/>
      </label>
    );
  };

  const customEndDateInput = (props) => {
    return (
      <label>
        <span className="date-label">{getTranslation("End", t)}</span>
        <DatePicker {...props} label={undefined} show={false}/>
      </label>
    );
  };

  return (
    <div className="transactional-log-panel">
      <h2>{getTranslation("Transactional log", t)}</h2>
      <div className="filters row p-0 m-0">
        <div className="col-12 col-lg-6 p-0 pe-lg-3 mb-4">
          <label>{getTranslation("User", t)}</label>
          <ComboBox
            className="k-input-flat"
            data={usersData}
            textField="name"
            dataItemKey="id"
            suggest={true}
            onChange={handleUserChange}
            onOpen={handleOpenUsersDropdown}
          />
        </div>
        <div className="col-12 col-lg-6 p-0 ps-lg-3 mb-4">
          <label>{getTranslation("Field", t)}</label>
          <FieldNamesDropdown
            data={fieldNameConfig}
            name="fieldName"
            textfield="textField"
            dataItemKey="value"
            value={fieldNameValue}
            handleChange={handleFieldName}
          />
        </div>
        <div className="daterange-picker col-12 col-lg-12 p-0  mb-4">
          <DateRangePicker
            className="k-input-flat"
            value={dateValue}
            format={"dd/MM/yyyy"}
            onChange={handleDateChange}
            startDateInput={customStartDateInput}
            endDateInput={customEndDateInput}
          />
        </div>
      </div>

      <div className="grid">
        <Grid
          data={data.data}
          skip={page.skip}
          take={page.take}
          total={data.totalRecords}
          pageable={{
            info: false,
            buttonCount: data.totalPages > 10 ? 10 : data.totalPages,
            pageSizes: [5, 10, 20],
            pageSizeValue
          }}
          onPageChange={pageChange}
        >
          <GridColumn field="dateTime" cell={transactionalLogsCell} />
        </Grid>
      </div>
    </div>
  );
};

export default TransactionalLogPanel;
