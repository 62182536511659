import React from "react";
import "../../styles/settings/settings.css";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";
import { checkMarketAu } from "../../utils/helpers";
import { Input } from "@progress/kendo-react-inputs";

const SettingsPanel = ({
  payload,
  payloadHolder,
  marketSettings,
  handleChange,
  handleFocusOut,
  handleSave,
  showError
}) => {
  const { t } = useTranslation();
  return (
    <div className="settings-panel">
      <h2>{getTranslation("Campaign", t)}</h2>
      {checkMarketAu() ? (
        <>
          <div
            className={`${
              showError && !payload.defaultInterestRate
                ? "error "
                : "input-settings"
            }`}
          >
            <label>{getTranslation("Default Interest rate", t)}</label>
            <Input
              name="defaultInterestRate"
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={payload.defaultInterestRate}
              className="k-input-flat"
            />
            {showError && !payload.defaultInterestRate ? (
              <p>{getTranslation("Please add an interest rate.", t)}</p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              showError && !payload.dealerOriginationFees
                ? "error "
                : "input-settings"
            }`}
          >
            <label>{getTranslation("Dealer Origination Fees", t)}</label>
            <Input
              name="dealerOriginationFees"
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={payload.dealerOriginationFees}
              className="k-input-flat"
            />
            {showError && !payload.dealerOriginationFees ? (
              <p>{getTranslation("Please add dealer origination fee.", t)}</p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              showError && !payload.ppsr ? "error " : "input-settings"
            }`}
          >
            <label>{getTranslation("PPSR (Government fee)", t)}</label>
            <Input
              name="ppsr"
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={payload.ppsr}
              className="k-input-flat"
            />
            {showError && !payload.ppsr ? (
              <p>{getTranslation("Please add PPSR (Government fee).", t)}</p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              showError && !payload.establishmentFee
                ? "error "
                : "input-settings"
            }`}
          >
            <label>{getTranslation("Establishment Fee", t)}</label>
            <Input
              name="establishmentFee"
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={payload.establishmentFee}
              className="k-input-flat"
            />
            {showError && !payload.establishmentFee ? (
              <p>{getTranslation("Please add an establishment fee.", t)}</p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              showError && !payload.accountKeepingFee
                ? "error "
                : "input-settings"
            }`}
          >
            <label>{getTranslation("Account Keeping Fee", t)}</label>
            <Input
              name="accountKeepingFee"
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={payload.accountKeepingFee}
              className="k-input-flat"
            />
            {showError && !payload.accountKeepingFee ? (
              <p>{getTranslation("Please add an account keeping fee.", t)}</p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              showError && !payload.annualKmAllowance
                ? "error "
                : "input-settings"
            }`}
          >
            <label>{getTranslation("Annual km Allowance (GFV only)", t)}</label>
            <Input
              name="annualKmAllowance"
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={payload.annualKmAllowance}
              className="k-input-flat"
            />
            {showError && !payload.annualKmAllowance ? (
              <p>
                {getTranslation(
                  "Please add an annual km Allowance (GFV only).",
                  t
                )}
              </p>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <div
          className={`${
            showError && !payload.defaultInterestRate
              ? "error "
              : "input-settings"
          }`}
        >
          <label>{getTranslation("Default Interest rate", t)}</label>
          <Input
            name="defaultInterestRate"
            onChange={handleChange}
            onBlur={handleFocusOut}
            value={payload.defaultInterestRate}
            className="k-input-flat"
          />
          {showError && !payload.defaultInterestRate ? (
            <p>{getTranslation("Please add an interest rate.", t)}</p>
          ) : (
            ""
          )}
        </div>
      )}

      <button
        onClick={handleSave}
        className="primary-btn"
        disabled={
          checkMarketAu()
            ? showError ||
              JSON.stringify(payloadHolder.current) === JSON.stringify(payload)
            : showError ||
              JSON.stringify(marketSettings?.defaultInterestRateFormatted) ===
                payload.defaultInterestRate
        }
      >
        {getTranslation("Save", t)}
      </button>
    </div>
  );
};

export default SettingsPanel;
